import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import ImageGallery from 'react-image-gallery';
import YoutubeEmbed from '../components/youtubeEmbed';

import '../utils/css/components/vendor/image-gallery.css';

import Layout from '../components/layout';
import SEO from '../components/seo';

const BookTemplate = ({ data, location }) => {
  const post = data.markdownRemark;
  const siteTitle = data.site.siteMetadata.title;
  const gallery = post.frontmatter.gallery.map(item => ({ original: item.publicURL, thumbnail: item.publicURL }));

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article
        className={`post-content ${post.frontmatter.thumb || 'no-image'}`}
      >
        {/* {post.frontmatter.video && (
          <YoutubeEmbed embedUrl={post.frontmatter.video} />
        )} */}
        <ImageGallery
          items={gallery}
          showNav={false}
          showFullscreenButton={false}
          showPlayButton={false}
          slideDuration={1}
          autoPlay={false}
          slideInterval={9999}
        />
        <br />
        <header className="post-content-header">
          <h3 className="post-content-title">{post.frontmatter.title}</h3>
        </header>
        { post.frontmatter.purchaseLink && (
          <div className="row">
            <div className="col" />
            <div className="col">
              <a href={post.frontmatter.purchaseLink} className="button print primary large fit buy-me-pulse" style={{ fontWeight: 600 }}>
                {post.frontmatter.purchaseLabel}
              </a>
            </div>
            <div className="col" />
          </div>
        )}
        {post.frontmatter.description && (
          <p className="post-content-excerpt">{post.frontmatter.description}</p>
        )}
        <div
          className="post-content-body"
          dangerouslySetInnerHTML={{ __html: post.html }}
        />
      </article>
    </Layout>
  );
};

export default BookTemplate;

export const pageQuery = graphql`
  query BookBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        video
        purchaseLink
        purchaseLabel
        gallery {
          absolutePath
          relativePath
          extension
          publicURL
        }
        hero {
          childImageSharp {
            fluid(maxWidth: 720) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
